import Slider from "react-slick";
import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Bhima from "../images/logos/bhima.jpeg";
import Bs from "../images/logos/bs.png";
import Ckcg from "../images/logos/ckcg.jpeg";
import Gj from "../images/logos/gj.png";
import Grt from "../images/logos/grt.png";
import Kalyan from "../images/logos/kalyan.png";
import Kanika from "../images/logos/kanika.jpeg";
import Malabar from "../images/logos/Malabar.webp";

export default class Logocloud extends Component {
  render() {
    const settings = {
      //   dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 3000,
      cssEase: "linear",
    };

    return (
      <div className="px-4 mt-10">
        <div
          className=" text-amber-500 mx-10  text-6xl  font-bold"
          style={{ fontFamily: "poppins" }}
        >
          OUR PARTICIPATES{" "}
        </div>

        <div className="w-full h-20 mt-10 ">
          <div class="    ">
            <Slider {...settings} className="">
              <div>
                <img class="h-72" src={Bhima} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Bs} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Ckcg} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Gj} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Grt} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Kalyan} alt="logo" />
              </div>
              <div>
                <img class="h-72" src={Kanika} alt="logo" />
              </div>

              <div>
                <img class="h-72" src={Malabar} alt="logo" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
