import React from "react";
import Typed from "react-typed";

export default function highlights() {
  return (
    <div class="dark:bg-gray-900">
      <div class="pb-20">
        <div class="mx-auto">
          <h1 class="text-center text-3xl text-amber-500">THE JEWELLERY SHOW</h1>
          <Typed
            className="flex justify-center mt-5 md:text-5xl sm:text-4xl text-xl font-bold text-amber-100"
            style={{ fontFamily: "poppins" }}
            strings={[
              "Our Happy Clients",
              "Project Completed",
              "Dedicated Members",
              "Awards Won",
            ]}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <div class="mx-auto mt-10 flex justify-center items-center backdrop-grayscale-0 bg-white/10 ">

          <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-x-2 gap-y-2 lg:gap-x-10 md:gap-x-6 md:gap-y-6">
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56  shadow rounded-2xl">
              <h2 class="lg:text-8xl md:text-4xl text-2xl leading-10 text-center text-amber-500">
                40+
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-white">
                Happy Clients
              </p>
            </div>
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56  shadow">
              <h2 class="lg:text-8xl md:text-4xl text-2xl leading-10 text-center text-amber-500">
                540+
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-white">
                Projects Completed
              </p>
            </div>
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56  shadow">
              <h2 class="lg:text-8xl md:text-4xl text-2xl leading-10 text-center text-amber-500">
                300+
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-white">
                Dedicated Members
              </p>
            </div>
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56  shadow">
              <h2 class="lg:text-8xl md:text-4xl text-2xl leading-10 text-center text-amber-500">
                25+
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center text-white">
                Awards Won
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
