import React from 'react'
import Amrapali from '../images/Amrapali.jpg'

export default function chiefguest() {
  return (
    <div class=''>
        <h1 class='text-center text-3xl font-semibold text-amber-500 mt-5'style={{fontFamily:'poppins'}}>INTRODUCING OUR CHIEF GUEST</h1>
        <section class="bg-blueGray-50">
  <div class=" mx-auto overflow-hidden">
    <div class="flex items-center justify-between px-4 py-5 bg-blueGray-50">
      <div class="w-auto">
        <div class="flex flex-wrap items-center">
          <div class="w-auto mr-14">
            <a href="#">
              <img src="flaro-assets/logos/flaro-logo-black.svg" alt=""/>
            </a>
          </div>
        </div>
      </div>
      
    </div>
    <div class="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
      <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
      <nav class="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto">
        <div class="flex flex-wrap justify-between h-full">
          <div class="w-full">
            <div class="flex items-center justify-between -m-2">
              <div class="w-auto p-2">
                <a class="inline-block" href="#">
                  <img src="flaro-assets/logos/flaro-logo-black.svg" alt=""/>
                </a>
              </div>
              <div class="w-auto p-2">
                <a class="navbar-burger" href="#">
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-end w-full pb-8">
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="block">
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="overflow-hidden pt-16 mx-20">
    <div class=" px-4 mx-auto">
      <div class="flex flex-wrap -m-8">
        <div class="w-full md:w-1/2 mx-10 lg:mx-20">
          <h1 class=" text-xl md:text-2xl md:max-w-xl leading-none font-semibold text-white" style={{fontFamily:'poppins'}}>HEARTHLY WELCOME TO OUR CHIEF GUEST
          <br/><span class='text-amber-500'>"Amrapali"</span> </h1>
          <p class="mb-5 text-xl text-white md:max-w-md font-semibold"style={{fontFamily:'poppins'}}>Model Actress</p>
        
          <p class="mb-6 text-base text-white font-semibold justify-center"style={{fontFamily:'poppins'}}>As the “Foremost Marketing Coach for Actors,” Gwyn was a successful actor herself working in all mediums. An Emmy Award nominee, she appeared on dozens of network daytime and primetime series. Her film scripts won awards from Beverly Hills to Cannes. She’s the premier role model with winning strategies and first-hand knowledge of the TV, film, and theater industries which she shares to help actors succeed. Request a complimentary 15-minute career session: gwyngilliss.com/contact. </p>
        </div>
        <div class="">
          <img class=""style={{width:'400px', height:'400px'}} src={Amrapali} alt=""/>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
