import React from "react";
import Fade from "react-reveal/Fade";
import Countdown, { zeroPad } from "react-countdown";
import Flip from "react-reveal/Flip";
import Jwe from "../images/Jewellerylogo.png";
import Typed from "react-typed";

export default function Comingsoon() {
  return (
    <div className="flex h-28 justify-evenly align-middle bg-[#E5D39A] "style={{ fontFamily: "poppins" }}
    >
      <img class="h-20 m-4 ml-20" src={Jwe}></img>

      <div
        class="text-orange-900 font-bold mt-4"
        style={{ fontFamily: "poppins" }}
      >
        <p class="text-md text-center">Upcoming Event</p>
        <p className="text-2xl font-bold">25, 26, 27th April 2023</p>
        <Typed
          className="flex justify-center text-slate-800 text-xl font-bold"
          style={{ fontFamily: "poppins" }}
          strings={["April 30th", "Aramane Maidana"]}
          typeSpeed={120}
          backSpeed={140}
          loop
        />
      </div>

      <Fade right>
        <div className=" ">
          <Countdown
            date={"2022-04-01T01:02:03"}
            renderer={({ days, hours, minutes, seconds }) => {
              console.log();
              return (
                <div className="sm:flex justify-center items-center w-full h-full sm:text-5xl text-xl ">
                  <div>
                    <div></div>
                    <div className="flex justify-center gap-5 mb-4">
                      <span
                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-orange-900"
                        style={{}}
                      >
                        {days} <br />{" "}
                        <div className="text-[13px] text-center"> Days </div>{" "}
                      </span>
                      <span
                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-orange-900"
                        style={{}}
                      >
                        {zeroPad(hours)}
                        <br />{" "}
                        <div className="text-[13px] text-center">
                          {" "}
                          Hours{" "}
                        </div>{" "}
                      </span>
                      <span
                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-orange-900"
                        style={{}}
                      >
                        {minutes}
                        <br />{" "}
                        <div className="text-[13px] text-center">
                          {" "}
                          Minutes{" "}
                        </div>{" "}
                      </span>
                      <span
                        className="p-1  rounded-lg  text-center text-[40px] font-bold text-orange-900"
                        style={{}}
                      >
                        {seconds}
                        <br />{" "}
                        <div className="text-[13px] text-center">
                          {" "}
                          Seconds{" "}
                        </div>{" "}
                      </span>
                    </div>

                    <div></div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </Fade>
    </div>
  );
}
