import React from 'react'
import Jwel from '../images/jwe.jpg'

export default function navbar() {
  return (
    <div class='w-full'>
    <img class='w-full' src={Jwel}></img>
    </div>
  )
}
