import React from "react";
import Banner from "../banner";
import Navbar from "../navbar";
import Gallery from "./gallery";


export default function Animated() {
  return (
    <div>
      <Navbar/>
    <Banner/>
    <Gallery />
    </div>
)}

