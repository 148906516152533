import React from 'react'
import '../App.css'

export default function marquee() {
  return (
    <div>
<article>
  <div class="wrapper font-semibold text-amber-50 text-3xl bg-gradient-to-br py-12 w-full from-orange-900 to-orange-600 rounded-xl">
    <ul class="marquee"style={{fontFamily:'Poppins'}}>
      <li>| SPREAD OVER 3,00,000 SQ.FT |</li>
      <li>| OVER 600 EXHIBITORS OVER 100 CITIES |</li>
      <li>| ROADSHOWS OVER 20,000 |</li>
      <li>| VISITORS OVER 2000 |</li>
      <li>| HOSTED BUYERSCELEBRATING A 5000-YEAR-OLD INDUSTRY |</li>
      <li>| CREATING A GRAND NEW LEGACY |</li>
    </ul>
  </div>
</article>
    </div>
  )
}
