import "./App.css";
import HomePage from "./components/index";
import { Route, Routes } from "react-router-dom";
import PreviousShow from "./components/privious-show/animated";
import UpcomingShows from './components/upcomingevents/upcoming';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/animated" element={<PreviousShow />} />
      <Route exact path="/upcoming" element={<UpcomingShows/>}/>
    </Routes>
  );
}

export default App;
