import Bglr1 from "../../images/banglore21/bangalore1.jpg";
import Bglr2 from "../../images/banglore21/bangalore2.jpg";
import Bglr3 from "../../images/banglore21/bangalore3.jpg";
import Bglr4 from "../../images/banglore21/bangalore4.jpg";

import Hub1 from "../../images/hubali/hubali1.jpg";
import Hub2 from "../../images/hubali/hubali2.jpg";
import Hub3 from "../../images/hubali/hubali3.jpg";

import Mang1 from "../../images/MANGALORE/m1.jpg";
import Mang2 from "../../images/MANGALORE/m2.jpg";
import Mang3 from "../../images/MANGALORE/m3.jpg";
import Mang4 from "../../images/MANGALORE/m4.jpg";
import Mang5 from "../../images/MANGALORE/m5.jpg";


import Chik1 from "../../images/Chikmagalur/chik1.jpg"
import Chik2 from "../../images/Chikmagalur/chik2.jpg"
import Chik3 from "../../images/Chikmagalur/chik3.jpg"
import Chik4 from "../../images/Chikmagalur/chik4.jpg"
import Chik5 from "../../images/Chikmagalur/chik5.jpg"
import Chik6 from "../../images/Chikmagalur/chik6.jpg"
import Chik7 from "../../images/Chikmagalur/chik7.jpg"
import Chik8 from "../../images/Chikmagalur/chik8.jpg"
import Chik9 from "../../images/Chikmagalur/chik9.jpg"
import Chik10 from "../../images/Chikmagalur/chik10.jpg"


import Dvg1 from "../../images/davanager-2019/davangere1.jpg"
import Dvg2 from "../../images/davanager-2019/davangere2.jpg"
import Dvg3 from "../../images/davanager-2019/davangere3.jpg"
import Dvg4 from "../../images/davanager-2019/davangere4.jpg"
import Dvg5 from "../../images/davanager-2019/davangere5.jpg"
import Dvg6 from "../../images/davanager-2019/davangere6.jpg"
import Dvg7 from "../../images/davanager-2019/davangere7.jpg"
import Dvg8 from "../../images/davanager-2019/davangere8.jpg"
import Dvg9 from "../../images/davanager-2019/davangere9.jpg"
import Dvg10 from "../../images/davanager-2019/davangere10.jpg"


import Ball1 from "../../images/ballari-2019/ballari22.jpg"
import Ball2 from "../../images/ballari-2019/ballari2.jpg"
import Ball3 from "../../images/ballari-2019/ballari3.jpg"
import Ball4 from "../../images/ballari-2019/ballari4.jpg"
import Ball5 from "../../images/ballari-2019/ballari5.jpg"
import Ball6 from "../../images/ballari-2019/ballari6.jpg"
import Ball7 from "../../images/ballari-2019/ballari7.jpg"
import Ball8 from "../../images/ballari-2019/ballari8.jpg"
import Ball9 from "../../images/ballari-2019/ballari9.jpg"
import Ball10 from "../../images/ballari-2019/ballari10.jpg"


import Mys1 from "../../images/Mysore/mysore1.jpg"
import Mys2 from "../../images/Mysore/mysore2.jpg"
import Mys3 from "../../images/Mysore/mysore3.jpg"
import Mys4 from "../../images/Mysore/mysore4.jpg"
import Mys5 from "../../images/Mysore/mysore 5.jpg"
import Mys6 from "../../images/Mysore/mysore6.jpg"
import Mys7 from "../../images/Mysore/mysore7.jpg"
import Mys8 from "../../images/Mysore/mysore8.jpg"
import Mys9 from "../../images/Mysore/mysore9.jpg"
import Mys10 from "../../images/Mysore/mysore10.jpg"

import Shiv2 from "../../images/Shivamogga/shivamogga2.jpg"
import Shiv3 from "../../images/Shivamogga/shivamogga3.jpg"
import Shiv4 from "../../images/Shivamogga/shivamogga4.jpg"
import Shiv5 from "../../images/Shivamogga/shivamogga5.jpg"
import Shiv6 from "../../images/Shivamogga/shivamogga6.jpg"
import Shiv1 from "../../images/Shivamogga/shivamogga1.jpg"
import Shiv7 from "../../images/Shivamogga/shivamogga7.jpg"
import Shiv8 from "../../images/Shivamogga/shivamogga8.jpg"
import Shiv9 from "../../images/Shivamogga/shivamogga9.jpg"
import Shiv10 from "../../images/Shivamogga/shivamogga10.jpg"







let arr = {
  Bengaluru: [Bglr1, Bglr2, Bglr3, Bglr4],
  Hubli: [Hub1, Hub2, Hub3],
  Mangaluru: [Mang1, Mang2, Mang3, Mang4, Mang5],
  chikkamagaluru: [Chik1,Chik2,Chik3,Chik4,Chik5,Chik6,Chik7,Chik8,Chik9,Chik10],
  davangere: [Dvg1,Dvg2,Dvg3,Dvg4,Dvg5,Dvg6,Dvg7,Dvg8,Dvg9,Dvg10],
  ballari: [Ball1,Ball2,Ball3,Ball4,Ball5,Ball6,Ball7,Ball8,Ball9,Ball10],
  mysore: [Mys1,Mys2,Mys3,Mys4,Mys5,Mys6,Mys7,Mys8,Mys9,Mys10],
  shivamoga: [Shiv1,Shiv2,Shiv3,Shiv4,Shiv5,Shiv6,Shiv7,Shiv8,Shiv9,Shiv10],
};

export default arr;
