import React, { useState } from "react";
import Footer from "../footer";
import Marquee from "../marquee";
import Navbar from "../navbar";
import Arr from "./store.js";

export default function Gallery() {
  const [isMenuOpen, setIsMenuOpen] = useState("");

  return (
    <div>
      <div>
        {/* <Navbar /> */}
        <Marquee />
      </div>

      <div
        class="text-center mt-10 text-4xl text-amber-500"
        style={{ fontFamily: "poppins" }}
      >
        Previous Shows
      </div>
      {/* .................................................................................................................................. */}

      <div class="flex">
        <div class="flex items-start">
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Bengaluru")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-home03"
              data-te-nav-active
              role="tab"
              aria-controls="tabs-home03"
              aria-selected="true"
            >
              Bangalore
            </a>
          </li>

          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Ballary")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent pt-4 px-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-home03"
              data-te-nav-active
              role="tab"
              aria-controls="tabs-home03"
              aria-selected="true"
            >
              Ballari
            </a>
          </li>

          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Mysore")}
              class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-profile03"
              role="tab"
              aria-controls="tabs-profile03"
              aria-selected="false"
            >
              Mysore
            </a>
          </li>
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Mangalore")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-messages03"
              role="tab"
              aria-controls="tabs-messages03"
              aria-selected="false"
            >
              Mangalore
            </a>
          </li>
        </div>
        {/* ..................................................................................................................................... */}
        <div class="flex items-start">
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Chikmagalur")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-home03"
              data-te-nav-active
              role="tab"
              aria-controls="tabs-home03"
              aria-selected="true"
            >
              Chikmagalur
            </a>
          </li>
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Hubbali")}
              class="focus:border-transparen my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-profile03"
              role="tab"
              aria-controls="tabs-profile03"
              aria-selected="false"
            >
              Hubbali
            </a>
          </li>
          {isMenuOpen == "Shivamogga" && (
            <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
              {Arr.shivamoga.map((v) => {
                return (
                  <img
                    alt="gallery"
                    class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                    src={v}
                  />
                );
              })}
            </div>
          )}
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Davangere")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-messages03"
              role="tab"
              aria-controls="tabs-messages03"
              aria-selected="false"
            >
              Davanager
            </a>
          </li>
          <li role="presentation" class="flex-grow text-center">
            <a
              onClick={() => setIsMenuOpen("Shivmogga")}
              class="my-2 block border-x-0 border-t-0 border-b-2 border-transparent px-4 pt-4 pb-3.5 text-lg font-medium uppercase leading-tight text-amber-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
              data-te-toggle="pill"
              data-te-target="#tabs-home03"
              data-te-nav-active
              role="tab"
              aria-controls="tabs-home03"
              aria-selected="true"
            >
              Shivmogga
            </a>
          </li>
        </div>
      </div>

      {/* ..................................................................................................................................... */}

      <section class="overflow-hidden text-gray-700">
        <div class="container px-5 py-2 mx-auto lg:pt-14 lg:px-32">
          <div class="flex flex-wrap -m-1 md:-m-2">
            <div class="flex flex-wrap w-full ">
              {isMenuOpen == "Mangalore" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.Mangaluru.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}

              {isMenuOpen == "Chikmagalur" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.chikkamagaluru.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}

              {isMenuOpen == "Bengaluru" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.Bengaluru.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            <div class="flex flex-wrap w-full">
              {isMenuOpen == "Mysore" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.mysore.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}

              {isMenuOpen == "Hubbali" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.Hubli.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}

              {isMenuOpen == "Shivmogga" && (
                <div class="w-full p-1 md:p-2 justify-evenly flex flex-wrap flex-row">
                  {Arr.shivamoga.map((v) => {
                    return (
                      <img
                        alt="gallery"
                        class="block object-cover object-center w-96 h-56 rounded-lg mt-10"
                        src={v}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
