import React from 'react'
import Jwel from '../../images/jwe.jpg'
import Navbar from '../navbar'
import Chiefguest from '../chiefguest'
import Footer from '../footer'
import Reactslick from '../reactslick'


export default function upcoming() {
  return (
    <div>
    <Navbar/>
    <div class='w-full'>
    <img class='w-full' src={Jwel}></img>
    </div>
   <Chiefguest/>
   <Reactslick/>
   <h1 class='text-amber-500 mt-72 text-center text-4xl font-semibold'style={{fontFamily:'poppins'}}>Registration</h1>
    <div class='flex mt-5'style={{fontFamily:'poppins'}}>
    <div class="w-[650px]">
       <div class="  max-w-lg  sm:mx-2 md:mx-12 px-16 md:px-0 md:mt-5">
       <p class="mb-8  mt-10 xl:mb-10 text-xl lg:text-4xl font-bold text-amber-500 mx-auto text-center lg:text-center"> Have any Question ?</p>
       <form>
                <div class=" mb-2 2xl:mb-4 grid-cols-2 ">
                  <input class="w-60 mb-2 sm:mb-0 sm:w-2/4 lg:w-full p-3 2xl:p-4 md:mr-4 text-indigo-800 font-semibold leading-none bg-blue-50 rounded-md"  type="text" id="first_name" placeholder="Name*"/>
                </div>
                <div class="mb-2 2xl:mb-4 grid-cols-2">
                  <input  class="w-60 mb-4 sm:mb-0 sm:w-2/4 lg:w-full p-3 2xl:p-4 md:mr-4 text-indigo-800 font-semibold leading-none bg-blue-50 rounded-md" type="email" id="email" placeholder="Email Address*"/>
                    </div>
                    <div class="mb-2 2xl:mb-4 grid-cols-2">
                  <input class=" w-60 mb-4 sm:mb-0 sm:w-2/4 lg:w-full p-3 2xl:p-4 md:mr-4 text-indigo-800 font-semibold leading-none bg-blue-50 rounded-md" type="text" id="phone_number" placeholder="Phone Number*"/>
                </div>
                <div class="mb-2 2xl:mb-4 grid-cols-2 ">
                  <input class=" w-60 mb-4 sm:mb-0 sm:w-full lg:w-full p-3 2xl:p-4 md:mr-4 text-indigo-800 font-semibold leading-none bg-blue-50 rounded-md" type="text" id="subject" placeholder="Subject"/>
                </div>
                  <div class="mb-2 2xl:mb-4">
                  <textarea  class="w-60 sm:w-full lg:w-full h-20 2xl:h-24 p-3 2xl:p-4 text-indigo-800 font-semibold leading-none resize-none bg-blue-50 rounded-md" rows="2" type="text" id="message" placeholder="Message(Optional)"></textarea>
                  </div>
                <div className="mt-2 2xl:mt-4 mb-0 2xl:mb-4">
                    <input className="mb-0  py-2 2xl:py-4 px-9 w-60 sm:w-full text-white bg-amber-500 font-semibold border  bg-gradient-to-r from-orange-900 to-amber-900 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 transition ease-in-out duration-200" value="Submit"   type="submit" />
                    </div>
              </form>
         </div>
         </div>
         <div className='py-4 w-[900px]'>
       <iframe className='h-[500px] w-full pr-5 mt-20' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3876.8914098025643!2d75.85113281482947!3d13.664366390404055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDM5JzUxLjciTiA3NcKwNTEnMTIuMCJF!5e0!3m2!1sen!2sin!4v1679046784747!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
 
    </div>



    <div>
    <Footer/>
  </div>

    </div>
  )
}
