import React from "react";
import { NavLink, Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div class="flex bg-gradient-to-r from-orange-900 to-amber-900 sticky top-0 z-10">
      <div
        class="text-lg text-white font-semibold ml-96"
        style={{ fontFamily: "Poppins" }}
      >
        <ul className=" flex justify-center">
          <NavLink to="/#" class="cursor-pointer text-white h-8 pr-6">
            Home
          </NavLink>

          <NavLink to="/animated" class=" cursor-pointer0 h-8 text-white pr-6">
            {" "}
            Previous Shows{" "}
          </NavLink>

          <NavLink to="/upcoming" class=" cursor-pointer text-white h-8 pr-6">
            Upcoming Shows{" "}
          </NavLink>
          <li1 class=" cursor-pointer text-white h-8">Event Gallery</li1>
          <li1 class=" cursor-pointer text-white h-8">Contact</li1>
        </ul>
      </div>
    </div>
  );
}
