import React from "react";
import Timer from "../components/timer";
import Navbar from "./navbar";
import Banner from "./banner";
import Marquee from "./marquee";
import Aboutus from "./aboutus";
import Highlights from "./highlights";
import Chiefguest from "./chiefguest";
import Testimonials from "./testomials";
import Glimse from "./glimse";
// import { Route, Routes } from "react-router-dom";
// import PreviousShow from "./privious-show/animated";
import Footer from '../components/footer'


function index() {
  return (
    <div>
      <Timer />
      <Navbar />
      <Banner />
      <Marquee />
      <Aboutus />
      <Highlights />
      {/* <Chiefguest /> */}
      <Testimonials />
      <Glimse />
      <Footer/>

    </div>
  );
}

export default index;
